import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom';
import StaticIntro from 'components/StaticIntro';
import TimeTrackingView from './TimeTrackingView';
import TrackingStatus from './TrackingStatus';

const TimeTrackingPage = () => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);

  const currentDate = new Date();
  const defaultMonth = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}`;

  const [tab, setTab] = useState(queryParams.get('tab') || 'timeTracking');
  const [email, setEmail] = useState(queryParams.get('email') || '');
  const [period, setPeriod] = useState(queryParams.get('period') || '');
  const [selectedMonth, setSelectedMonth] = useState(queryParams.get('status-period') || defaultMonth);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    params.set('tab', tab);

    if (tab === 'timeTracking') {
      if (email) params.set('email', email);
      if (period) params.set('period', period);
      params.delete('status-period');
    } else {
      params.delete('email');
      params.delete('period');
      params.set('status-period', selectedMonth);
    }

    history.replace({ search: params.toString() });
  }, [tab, email, period, selectedMonth, history, location.search]);

  const handleEmailChange = (newEmail) => {
    setEmail(newEmail);
  };

  const handlePeriodChange = (newPeriod) => {
    setPeriod(newPeriod);
  };

  return (
    <>
      <StaticIntro pretitle="Management" title="Time Tracking" />

      <div style={{ width: '40%', float: 'left' }}>
        <Button variant="outlined" onClick={() => setTab('timeTracking')} sx={{ mr: 2 }}>
          Individuals
        </Button>
        <Button variant="outlined" onClick={() => setTab('totalBreakdown')}>
          Team
        </Button>
      </div>

      {tab === 'timeTracking' ? (
        <TimeTrackingView
          email={email}
          period={period}
          onEmailChange={handleEmailChange}
          onPeriodChange={handlePeriodChange}
        />
      ) : (
        <TrackingStatus selectedMonth={selectedMonth} setSelectedMonth={setSelectedMonth} />
      )}
    </>
  );
};

export default TimeTrackingPage;
