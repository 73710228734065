import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import ProjectTasksCard from 'components/Cards/ProjectTasksCard';
import Tabs from 'components/Tabs';
import SubProjects from './SubProjects';

const ContinuousImprovementsPage = ({
  id,
  projectData,
  error,
  loadingTable,
  setLoadingTable,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  months,
  setMonths,
  selectedMonth,
  setShowOpen,
  setSelectedMonth,
  relatedProjects
}) => {
  const location = useLocation();
  const history = useHistory();
  const [tab, setTab] = useState(0);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tabParam = params.get('tab');
    if (tabParam === 'related') {
      setTab(1);
    } else {
      setTab(0);
    }
  }, [location.search]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    const tabParam = newValue === 1 ? 'related' : 'ci';
    history.push(`?tab=${tabParam}`);
  };

  return (
    <>
      <Tabs
        tab={tab}
        tabs={['Continuous Improvements', 'Related Projects']}
        handleTabChange={handleTabChange}
        sx={{ mb: 5 }}
      />
      {tab === 0 && (
        <ProjectTasksCard
          id={id}
          data={projectData}
          error={error}
          loading={loadingTable}
          setLoadingTable={setLoadingTable}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          startDate={startDate}
          endDate={endDate}
          months={months}
          setMonths={setMonths}
          selectedMonth={selectedMonth}
          setShowOpen={setShowOpen}
          setSelectedMonth={setSelectedMonth}
        />
      )}

      {tab === 1 && <SubProjects relatedProjects={relatedProjects} />}
    </>
  );
};

export default ContinuousImprovementsPage;
