import { gql } from '@apollo/client';

export const TRACKING_STATUS = gql`
  query projects($startDate: String, $endDate: String, $type: String) {
    projects(type: $type) {
      id
      title
      supportType {
        label
      }
      tasks(startDate: $startDate, endDate: $endDate) {
        allowance {
          totalAvailableMinutes
          totalSpentMinutes
          totalPerDay {
            date
            time
            tasks {
              key
              fields {
                summary
              }
            }
          }
        }
      }
    }
  }
`;
