import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { startCase } from 'helpers/content';
import { Link } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import { StyledChip } from './styles';
import { LIST_ALL_USERS_QUERY } from './data';
import Tabs from 'components/Tabs';
import Table from 'components/Table';
import StaticIntro from 'components/StaticIntro';
import AvatarGenerator from 'components/AvatarGenerator';
import LoadingCardWrapper from 'components/Wrappers/LoadingCardWrapper';
import * as ROUTES from 'constants/routes';

const UsersPage = () => {
  const [tab, setTab] = useState(0);
  const { loading, error, data } = useQuery(LIST_ALL_USERS_QUERY);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  let role = 'normal';
  if (tab === 1) {
    role = 'superAdmin';
  }

  const { users } = data || {};

  return (
    <>
      <StaticIntro pretitle="Management" title="Users" />

      {loading && <LoadingCardWrapper page={true} />}
      {error && 'Currently unavailable'}
      {!loading && !error && (
        <Grid container>
          <Grid item xs={12}>
            <Tabs tab={tab} tabs={['Users', 'Admins']} handleTabChange={handleTabChange} />

            <Table
              title="Manage Users"
              columns={[
                { title: 'Avatar', field: 'avatar' },
                { title: 'First Name', field: 'firstname' },
                { title: 'Last Name', field: 'lastname' },
                { title: 'Email', field: 'email' },
                { title: 'Role', field: 'role' },
                { title: 'Projects', field: 'projectCount' },
                { title: 'Verified', field: 'verified' },
                { title: 'Actions', field: 'userProfile' }
              ]}
              data={users
                .filter((user) => user.role === role)
                .map(({ uid, firstname, lastname, role, email, emailVerified, projectCount }, index) => {
                  return {
                    avatar: <AvatarGenerator email={email} firstname={firstname} lastname={lastname} size={38} />,
                    firstname,
                    lastname,
                    email,
                    role: startCase(role),
                    projectCount,
                    verified: (
                      <StyledChip
                        label={emailVerified ? 'Verified' : 'Not Verified'}
                        size="small"
                        variant="outlined"
                        verified={emailVerified ? emailVerified.toString() : 'false'}
                      />
                    ),
                    userProfile: (
                      <Button
                        component={Link}
                        variant="outlined"
                        size="small"
                        to={`${ROUTES.DASHBOARD_USER_PROFILE_ROOT}/${uid}`}
                      >
                        View
                      </Button>
                    )
                  };
                })}
              options={{
                pageSize: users.length < 20 ? users.length : 20,
                sorting: true,
                draggable: false
              }}
              style={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UsersPage;
