import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank, Visibility, VisibilityOff } from '@mui/icons-material';
import {
  SignInLink,
  IconButtonPosition,
  CenterButton,
  ErrorMessage,
  StyledErrorHelperText,
  StyledLabel
} from './styles';
import { red } from '@mui/material/colors';
import { doCreateUserWithEmailAndPassword } from 'actions/auth/createUser';
import { setSignupError } from 'reducers/errorReducer';
import { validationSchema } from './validation';
import { StyledField } from 'components/Forms/LoginForm/styles';
import LoadingSpinner from 'components/Loaders/LoadingSpinner';
import * as ROUTES from 'constants/routes';

const RegistrationForm = () => {
  const dispatch = useDispatch();
  const { signupError } = useSelector((state) => state.errors);
  const { lightMode } = useSelector((state) => state.type);
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  // Watch all fields to reset submit state
  watch((data) => {
    if (isSubmitting) {
      setIsSubmitting(false);
    }
    if (signupError) {
      dispatch(setSignupError());
    }
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async ({ fname, lname, email, password1 }) => {
    setIsSubmitting(true);
    try {
      await dispatch(doCreateUserWithEmailAndPassword(fname, lname, email, password1));
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <StyledField
        {...register('fname')}
        label="First name"
        autoComplete="fname"
        margin="normal"
        required
        fullWidth
        disabled={false}
        color="primary"
        error={!!errors.fname}
        helperText={errors.fname?.message}
      />
      <StyledField
        {...register('lname')}
        label="Last name"
        autoComplete="lname"
        margin="normal"
        required
        fullWidth
        disabled={false}
        color="primary"
        error={!!errors.lname}
        helperText={errors.lname?.message}
      />
      <StyledField
        {...register('email')}
        label="Email"
        autoComplete="email"
        margin="normal"
        required
        fullWidth
        disabled={false}
        color="primary"
        error={!!errors.email}
        helperText={errors.email?.message}
      />
      <FormControl fullWidth>
        <StyledField
          {...register('password1')}
          label="Password"
          type={showPassword ? 'text' : 'password'}
          autoComplete="password"
          margin="normal"
          required
          fullWidth
          disabled={false}
          color="primary"
          error={!!errors.password1}
          helperText={errors.password1?.message}
        />
        <IconButtonPosition
          color={lightMode ? 'primary' : 'secondary'}
          aria-label="Toggle password visibility"
          onClick={handleClickShowPassword}
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButtonPosition>
      </FormControl>

      <StyledField
        {...register('password2')}
        label="Confirm password"
        type="password"
        margin="normal"
        required
        fullWidth
        disabled={false}
        style={{ marginBottom: '30px' }}
        color="primary"
        error={!!errors.password2}
        helperText={errors.password2?.message}
      />

      <FormControl component="fieldset" error={!!errors.agree}>
        <FormControlLabel
          control={
            <Checkbox
              {...register('agree', { required: true })}
              icon={<CheckBoxOutlineBlank />}
              checkedIcon={<CheckBox />}
              sx={{ color: 'currentColor', '&.Mui-checked': { color: 'black' } }}
            />
          }
          label={
            <StyledLabel>
              I accept the{' '}
              <a href="https://andanotherday.com/about/terms-of-use/" target="_blank" rel="noreferrer">
                terms of use
              </a>
            </StyledLabel>
          }
          sx={{ color: 'black', '&.Mui-checked': { color: 'black' }, '&.Mui-error': { color: 'red' } }}
        />
        {errors.agree && <StyledErrorHelperText>This field is required</StyledErrorHelperText>}
      </FormControl>

      {signupError && <ErrorMessage component="p">{signupError}</ErrorMessage>}

      <CenterButton>
        <Button
          type="submit"
          size="large"
          variant="contained"
          color="secondary"
          disabled={isSubmitting}
          sx={{
            '&.Mui-disabled': {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: red[700]
            }
          }}
        >
          {isSubmitting && !signupError && <LoadingSpinner size={20} />}
          {signupError && 'Error signing up'}
          {!isSubmitting && !signupError && 'Sign Up'}
        </Button>
      </CenterButton>
      <CenterButton>
        <SignInLink component={Link} to={ROUTES.AUTH_SIGN_IN}>
          Already have an account? Sign In
        </SignInLink>
      </CenterButton>
    </form>
  );
};

export default RegistrationForm;
