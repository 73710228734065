import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Paper, Grid, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { HelpOutline, PersonOutlined, DescriptionOutlined, AlarmOn, ErrorOutline } from '@mui/icons-material';
import { verifyEmail } from 'actions/auth/emailVerify';
import { VERIFICATION_EMAIL_MUTATION } from '../../../Auth/VerifyEmail/data';
import PageLoader from 'components/Loaders/PageLoader';

const allowedFeatures = [
  {
    icon: <HelpOutline />,
    title: 'Raise support tickets'
  },
  {
    icon: <PersonOutlined />,
    title: 'Update your profile'
  },
  {
    icon: <DescriptionOutlined />,
    title: 'View your monthly reports'
  },
  {
    icon: <AlarmOn />,
    title: 'More features coming soon...'
  }
];

const EmailVerificationPage = ({ route }) => {
  const dispatch = useDispatch();
  const { search } = route.location;
  const token = new URLSearchParams(search).get('t');
  const { verified } = useSelector((state) => state.auth);

  const [requestVerificationEmail, { loading, data }] = useMutation(VERIFICATION_EMAIL_MUTATION);
  const [verificationStatus, setVerificationStatus] = useState('loading'); // 'loading', 'success', 'error'

  useEffect(() => {
    const doVerification = async () => {
      if (!token || verified) {
        setVerificationStatus('success');
        return;
      }

      try {
        await dispatch(verifyEmail(token));
        setVerificationStatus('success');
      } catch (err) {
        setVerificationStatus('error');
      }
    };

    doVerification();
  }, [token, verified, dispatch]);

  const renderContent = () => {
    if (verificationStatus === 'loading') {
      return <PageLoader />;
    }

    if (verificationStatus === 'error') {
      return (
        <Paper sx={{ p: 3, textAlign: 'center' }}>
          <ErrorOutline color="error" sx={{ fontSize: 64, mb: 2 }} />
          <Typography component="h1" variant="h4" gutterBottom>
            Verification Failed
          </Typography>
          <Typography gutterBottom>
            We were unable to verify your email address. The verification link may have expired or is invalid.
          </Typography>
          <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={requestVerificationEmail}>
            {loading ? 'Check your emails' : data ? 'Try again' : 'Re-send verification email'}
          </Button>
        </Paper>
      );
    }

    return (
      <>
        <Typography component="h1" variant="h4">
          Congratulations!
        </Typography>
        <Typography gutterBottom component="p">
          You have now verified your email address. You can now do the following things:
        </Typography>
        <Paper>
          <List>
            {allowedFeatures.map(({ icon, title }, index) => (
              <ListItem key={index}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={title} />
              </ListItem>
            ))}
          </List>
        </Paper>
      </>
    );
  };

  return (
    <Grid container>
      <Grid item xs={4} />
      <Grid item xs={4}>
        {renderContent()}
      </Grid>
      <Grid item xs={4} />
    </Grid>
  );
};

export default EmailVerificationPage;
