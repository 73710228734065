import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Button, Typography } from '@mui/material';
import { StyledChip } from './styles';
import { Edit } from '@mui/icons-material';
import { validationSchema } from './validation';
import { yupResolver } from '@hookform/resolvers/yup';

const UpdateUserDetailsForm = ({ user: { firstname, lastname, email, emailVerified } }) => {
  const [editMode, setEditMode] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      firstname,
      lastname,
      email
    },
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="body" component="p" gutterBottom>
        Use the form below to update the users details.
      </Typography>

      <Controller
        name="firstname"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            variant="outlined"
            label="First name"
            margin="normal"
            required
            fullWidth
            disabled={!editMode}
            error={!!errors.firstname}
            helperText={errors.firstname ? errors.firstname.message : ''}
          />
        )}
      />
      <Controller
        name="lastname"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            variant="outlined"
            label="Last name"
            margin="normal"
            required
            fullWidth
            disabled={!editMode}
            error={!!errors.lastname}
            helperText={errors.lastname ? errors.lastname.message : ''}
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            variant="outlined"
            label="Email"
            margin="normal"
            required
            fullWidth
            disabled
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : ''}
          />
        )}
      />

      <Typography variant="h6" component="h6" sx={{ mt: 3 }}>
        Verification Status
      </Typography>
      <StyledChip
        label={emailVerified ? 'Verified' : 'Not Verified'}
        variant="outlined"
        verified={emailVerified ? emailVerified.toString() : 'false'}
        sx={{ mt: 1 }}
      />

      <Typography variant="body" component="p" sx={{ mt: 3 }}>
        {!editMode ? (
          <Button variant="outlined" color="primary" startIcon={<Edit />} onClick={() => setEditMode(true)}>
            Edit
          </Button>
        ) : (
          <Button type="submit" variant="contained" color="primary">
            Update
          </Button>
        )}
      </Typography>
    </form>
  );
};

export default UpdateUserDetailsForm;
