import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, FormControl } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ForgotLink, IconButtonPosition, ErrorMessage, StyledField } from './styles';
import { doSignInWithEmailAndPassword } from 'actions/auth/signIn';
import { setLoginError } from 'reducers/errorReducer';
import { validationSchema } from './validation';
import LoadingSpinner from 'components/Loaders/LoadingSpinner';
import * as ROUTES from 'constants/routes';

const LoginForm = () => {
  const dispatch = useDispatch();
  const { loginError } = useSelector((state) => state.errors);
  const { lightMode } = useSelector((state) => state.type);
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  // Watch all fields to reset submit state
  watch((data) => {
    if (isSubmitting) {
      setIsSubmitting(false);
    }
    if (loginError) {
      dispatch(setLoginError());
    }
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async ({ username, password }) => {
    setIsSubmitting(true);
    try {
      await dispatch(doSignInWithEmailAndPassword(username, password));
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <StyledField
        {...register('username')}
        label="Email"
        autoComplete="email"
        margin="normal"
        required
        fullWidth
        disabled={false}
        color="primary"
        error={!!errors.username}
        helperText={errors.username?.message}
      />

      <FormControl fullWidth style={{ marginBottom: '30px' }}>
        <StyledField
          {...register('password')}
          label="Password"
          type={showPassword ? 'text' : 'password'}
          autoComplete="password"
          margin="normal"
          required
          fullWidth
          disabled={false}
          error={!!errors.password}
          helperText={errors.password?.message}
        />
        <IconButtonPosition
          color={lightMode ? 'primary' : 'secondary'}
          aria-label="Toggle password visibility"
          onClick={handleClickShowPassword}
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButtonPosition>
      </FormControl>

      <ForgotLink to={ROUTES.AUTH_PASSWORD_FORGET}>Forgot Password?</ForgotLink>

      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Button
            type="submit"
            size="large"
            variant="contained"
            color="secondary"
            style={{ backgroundColor: '#000' }}
            fullWidth
            disabled={isSubmitting}
          >
            {isSubmitting && !loginError && <LoadingSpinner size={20} />}
            {loginError && 'Error logging in'}
            {!isSubmitting && !loginError && 'Login'}
          </Button>
        </Grid>

        <Grid item xs={6}>
          <Button component={Link} size="large" variant="outlined" color="secondary" fullWidth to={ROUTES.AUTH_SIGN_UP}>
            Sign Up
          </Button>
        </Grid>
      </Grid>

      {loginError && <ErrorMessage component="p">{loginError}</ErrorMessage>}
    </form>
  );
};

export default LoginForm;
