import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Button, FormControl } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { validationSchema } from './validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconButtonPosition } from 'components/Forms/RegistrationForm/styles';

const UpdateUserPasswordForm = () => {
  const { lightMode } = useSelector((state) => state.type);
  const [showPassword, setShowPassword] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      password1: '',
      password2: ''
    },
    resolver: yupResolver(validationSchema)
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = ({ password1 }) => {
    console.log('password1', password1);
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <FormControl fullWidth>
        <Controller
          name="password1"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Password"
              type={showPassword ? 'text' : 'password'}
              autoComplete="password"
              margin="normal"
              required
              fullWidth
              error={!!errors.password1}
              helperText={errors.password1 ? errors.password1.message : ''}
            />
          )}
        />
        <IconButtonPosition
          color={lightMode ? 'primary' : 'secondary'}
          aria-label="Toggle password visibility"
          onClick={handleClickShowPassword}
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButtonPosition>
      </FormControl>

      <Controller
        name="password2"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Confirm password"
            type="password"
            margin="normal"
            required
            fullWidth
            error={!!errors.password2}
            helperText={errors.password2 ? errors.password2.message : ''}
            style={{ marginBottom: '30px' }}
          />
        )}
      />

      <Button type="submit" size="large" variant="contained" color={lightMode ? 'primary' : 'secondary'}>
        Update Password
      </Button>
    </form>
  );
};

export default UpdateUserPasswordForm;
