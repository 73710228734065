// src/reducers/authReducer.js
import { createSlice } from '@reduxjs/toolkit';

/** Initial state */
const initState = {
  authError: null,
  regComplete: false,
  firstname: null,
  lastname: null,
  email: null,
  role: null,
  verified: false,
  isVerifying: false,
  verifyError: null,
  loggedIn: false,
  projectCount: 0,
  agencies: null,
  projects: null,
  features: [],
  support: {
    openCount: 0,
    closedCount: 0
  }
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initState,
  reducers: {
    setUser: (state, action) => {
      state.firstname = action.payload.firstname;
      state.lastname = action.payload.lastname;
      state.email = action.payload.email;
      state.role = action.payload.role;
      state.loggedIn = true;
      state.projectCount = action.payload.projectCount;
      state.agencies = action.payload.agencies;
      state.projects = action.payload.projects;
      state.support = action.payload.support;
      state.features = action.payload.features;
    },
    signoutSuccess: (state) => {
      Object.assign(state, initState);
    },
    signupSuccess: (state, action) => {
      authSlice.caseReducers.setUser(state, action);
      state.regComplete = true;
    },
    setSupportCounts: (state, action) => {
      state.support = action.payload;
    },
    setProjectCount: (state, action) => {
      state.projectCount = action.payload;
    },
    verificationStart: (state) => {
      state.isVerifying = true;
      state.verifyError = null;
    },
    verificationSuccess: (state, action) => {
      state.isVerifying = false;
      state.verified = true;
      state.verifyError = null;
    },
    verificationError: (state, action) => {
      state.isVerifying = false;
      state.verifyError = action.payload;
    }
  }
});

export const {
  setUser,
  signoutSuccess,
  signupSuccess,
  setSupportCounts,
  setProjectCount,
  verificationStart,
  verificationSuccess,
  verificationError
} = authSlice.actions;

export default authSlice.reducer;
