import moment from 'moment';
import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Grid, MenuItem, Select, Stack, Tooltip, Typography } from '@mui/material';
import { TRACKING_STATUS } from './data';
import LoadingCardWrapper from 'components/Wrappers/LoadingCardWrapper';

const getDaySuffix = (day) => {
  if (day > 3 && day < 21) return 'th'; // Handle 11th, 12th, 13th
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

const isWeekend = (year, month, day) => {
  const date = new Date(year, month - 1, day);
  return date.getDay() === 0 || date.getDay() === 6;
};

// Function to generate table headers from data
const generateDateHeaders = (data, selectedMonth) => {
  if (!Array.isArray(data)) return [];

  const [year, month] = selectedMonth.split('-');

  const days = [
    ...new Set(
      data
        .flatMap((project) => project?.tasks?.allowance?.totalPerDay || [])
        .map((day) => parseInt(day?.date?.split('-')[2]))
        .filter(Boolean)
    )
  ].sort((a, b) => a - b);

  return days.map((day) => {
    const isWeekendDay = isWeekend(parseInt(year), parseInt(month), day);
    return (
      <th
        key={day}
        style={{
          border: '1px solid rgba(255,255,255,0.5)',
          padding: '4px',
          backgroundColor: isWeekendDay ? '#333333' : 'transparent'
        }}
      >
        {day}
        {getDaySuffix(day)}
      </th>
    );
  });
};

const TeamTrackingView = ({ selectedMonth, setSelectedMonth }) => {
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY/MM/DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY/MM/DD'));

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const handleMonthChange = (event) => {
    const [year, month] = event.target.value.split('-');
    const lastDay = new Date(year, month, 0).getDate();

    setSelectedMonth(event.target.value);
    setStartDate(`${year}/${month}/01`);
    setEndDate(`${year}/${month}/${lastDay}`);
  };

  // Generate month options for last 12 months
  const getMonthOptions = () => {
    const options = [];
    const today = new Date();

    for (let i = 0; i < 12; i++) {
      const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
      const value = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
      const label = `${months[date.getMonth()]} ${date.getFullYear()}`;
      options.push({ value, label });
    }

    return options;
  };

  const { loading, error, data } = useQuery(TRACKING_STATUS, {
    variables: {
      startDate,
      endDate,
      type: 'support'
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const calculatePercentage = (spent, available) => {
    if (!available) return spent > 0 ? '100.00' : '0.00';
    return ((spent / available) * 100).toFixed(2);
  };

  const projects = data?.projects?.slice()?.sort((a, b) => a.title.localeCompare(b.title)) || [];

  return (
    <>
      <div style={{ width: '60%', float: 'right' }}>
        <Grid container spacing={2} sx={{ mb: 5 }}>
          <Grid item xs={12} container justifyContent="flex-end">
            <Select
              value={selectedMonth}
              onChange={handleMonthChange}
              sx={{
                minWidth: 200,
                '.MuiSelect-select': {
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: '8px',
                  paddingBottom: '8px'
                }
              }}
            >
              {getMonthOptions().map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack spacing={2} direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
            <Typography component="h5" variant="h5">
              <strong>Team Overview</strong>
            </Typography>
          </Stack>

          {loading && <LoadingCardWrapper page={true} />}
          {error && 'Currently unavailable'}
          {!loading && !error && (
            <div style={{ display: 'flex', overflow: 'auto' }}>
              <table
                style={{
                  width: '100%',
                  border: '1px solid rgba(255,255,255,0.5)',
                  borderCollapse: 'collapse',
                  marginRight: '0'
                }}
              >
                <thead>
                  <tr>
                    <th style={{ border: '1px solid rgba(255,255,255,0.5)', padding: '4px' }}>Project</th>
                    <th style={{ border: '1px solid rgba(255,255,255,0.5)', padding: '4px' }}>Type</th>
                    <th style={{ border: '1px solid rgba(255,255,255,0.5)', padding: '4px' }}>Expected</th>
                    <th style={{ border: '1px solid rgba(255,255,255,0.5)', padding: '4px' }}>Used</th>
                    <th style={{ border: '1px solid rgba(255,255,255,0.5)', padding: '4px' }}>Percentage</th>
                    {generateDateHeaders(projects, selectedMonth)}
                  </tr>
                </thead>
                <tbody>
                  {projects?.map((project, index) => (
                    <tr key={index}>
                      <td style={{ border: '1px solid rgba(255,255,255,0.5)', padding: '4px', whiteSpace: 'nowrap' }}>
                        {project.title}
                      </td>
                      <td style={{ border: '1px solid rgba(255,255,255,0.5)', padding: '4px', whiteSpace: 'nowrap' }}>
                        {project.supportType?.label === 'Continuous Improvement' ? 'CI' : 'Support'}
                      </td>
                      <td style={{ border: '1px solid rgba(255,255,255,0.5)', padding: '4px' }}>
                        {(project.tasks.allowance.totalAvailableMinutes / 60).toFixed(2)}
                      </td>
                      <td style={{ border: '1px solid rgba(255,255,255,0.5)', padding: '4px' }}>
                        {(project.tasks.allowance.totalSpentMinutes / 60).toFixed(2)}
                      </td>
                      <td
                        style={{
                          background:
                            (
                              (project.tasks.allowance.totalSpentMinutes /
                                project.tasks.allowance.totalAvailableMinutes) *
                              100
                            ).toFixed(2) > 100
                              ? 'red'
                              : '',
                          border: '1px solid rgba(255,255,255,0.5)',
                          padding: '4px'
                        }}
                      >
                        {calculatePercentage(
                          project.tasks.allowance.totalSpentMinutes,
                          project.tasks.allowance.totalAvailableMinutes
                        )}
                        %
                      </td>
                      {project.tasks.allowance.totalPerDay.map(({ time, date, tasks }, cellIndex) => {
                        const day = parseInt(date.split('-')[2]);
                        const isWeekendDay = isWeekend(
                          parseInt(selectedMonth.split('-')[0]),
                          parseInt(selectedMonth.split('-')[1]),
                          day
                        );

                        // Find index of first overrun
                        const firstOverrunIndex = project.tasks.allowance.totalPerDay.findIndex((_, index) => {
                          const totalTime = project.tasks.allowance.totalPerDay
                            .slice(0, index + 1)
                            .reduce((sum, { time: t }) => sum + t, 0);
                          return totalTime > project.tasks.allowance.totalAvailableMinutes;
                        });

                        const cellStyle = (isWeekendDay, cellIndex, firstOverrunIndex) => {
                          if (cellIndex === firstOverrunIndex) return '#ff0000';
                          return isWeekendDay ? '#333333' : 'transparent';
                        };

                        return (
                          <td
                            key={cellIndex}
                            style={{
                              border: '1px solid rgba(255,255,255,0.5)',
                              textAlign: 'center',
                              backgroundColor: cellStyle(isWeekendDay, cellIndex, firstOverrunIndex),
                              cursor: 'pointer'
                            }}
                          >
                            <Tooltip
                              title={
                                tasks?.length ? (
                                  <div>
                                    {[...new Map(tasks.map((task) => [task.key, task])).values()].map((task, i) => (
                                      <div key={task.key || i} style={{ padding: '4px 0' }}>
                                        {task.summary}
                                        {task.key && (
                                          <a
                                            href={`https://andanotherday.atlassian.net/browse/${task.key}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ fontSize: '12px' }}
                                          >
                                            {task.key}: {task.fields.summary}
                                          </a>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  'No tasks'
                                )
                              }
                              arrow
                              placement="top"
                            >
                              <span style={{ padding: '4px' }}>
                                {time === 0
                                  ? ''
                                  : (time / 60) % 1 === 0
                                  ? Math.floor(time / 60)
                                  : (time / 60).toFixed(2)}
                              </span>
                            </Tooltip>
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>

                <tfoot style={{ background: '#333333' }}>
                  <tr>
                    <td
                      style={{
                        border: '1px solid rgba(255,255,255,0.5)',
                        padding: '4px'
                      }}
                    >
                      <strong>Total</strong>
                    </td>
                    <td
                      style={{
                        border: '1px solid rgba(255,255,255,0.5)',
                        padding: '4px'
                      }}
                    ></td>
                    <td
                      style={{
                        border: '1px solid rgba(255,255,255,0.5)',
                        padding: '4px'
                      }}
                    >
                      <strong>
                        {projects.reduce((acc, project) => acc + project.tasks.allowance.totalAvailableMinutes, 0) / 60}
                      </strong>
                    </td>
                    <td
                      style={{
                        border: '1px solid rgba(255,255,255,0.5)',
                        padding: '4px'
                      }}
                    >
                      <strong>
                        {projects.reduce((acc, project) => acc + project.tasks.allowance.totalSpentMinutes, 0) / 60}
                      </strong>
                    </td>
                    <td
                      style={{
                        border: '1px solid rgba(255,255,255,0.5)',
                        padding: '4px'
                      }}
                    >
                      <strong>
                        {calculatePercentage(
                          projects.reduce((acc, project) => acc + project.tasks.allowance.totalSpentMinutes, 0),
                          projects.reduce((acc, project) => acc + project.tasks.allowance.totalAvailableMinutes, 0)
                        )}
                        %
                      </strong>
                    </td>
                    {projects &&
                      projects[0]?.tasks?.allowance?.totalPerDay.map(({ time, date }, cellIndex) => {
                        return <td key={cellIndex}></td>;
                      })}
                  </tr>
                </tfoot>
              </table>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default TeamTrackingView;
