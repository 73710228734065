import moment from 'moment';
import { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormControlLabel, Grid, MenuItem, Select, Stack, Switch, TextField, Typography } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useQuery } from '@apollo/client';
import { formatDuration } from 'helpers/content';
import { TIME_TRACKING } from './data';
import AvatarGenerator from 'components/AvatarGenerator';
import LoadingCardWrapper from 'components/Wrappers/LoadingCardWrapper';
import TimeTrackingTable from './TimeTrackingTable';

const TimeTrackingView = ({ email, period, onEmailChange, onPeriodChange }) => {
  const { email: authEmail } = useSelector((state) => state.auth);

  const [tab, setTab] = useState(email);
  const [selectedOption, setSelectedOption] = useState(period || 'thisWeek');
  const [startDate, setStartDate] = useState(moment().startOf('week').add(1, 'days').format('YYYY/MM/DD'));
  const [endDate, setEndDate] = useState(moment().endOf('week').add(1, 'days').format('YYYY/MM/DD'));
  const [isCustom, setIsCustom] = useState(false);

  const { loading, error, data, refetch } = useQuery(TIME_TRACKING, {
    variables: {
      startDate,
      endDate
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  useEffect(() => {
    setDates(selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    if (data && data.timeTracking && data.timeTracking.length > 0) {
      if (!tab) {
        setTab(authEmail);
      }
    }
  }, [data, authEmail, tab]);

  useEffect(() => {
    onEmailChange(tab);
    onPeriodChange(selectedOption);
  }, [tab, selectedOption, onEmailChange, onPeriodChange]);

  const setDates = (option) => {
    let start, end;
    switch (option) {
      case 'thisWeek':
        start = moment().startOf('isoWeek').format('YYYY/MM/DD');
        end = moment().endOf('isoWeek').format('YYYY/MM/DD');
        break;
      case 'lastWeek':
        start = moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY/MM/DD');
        end = moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY/MM/DD');
        break;
      case 'thisMonth':
        start = moment().startOf('month').format('YYYY/MM/DD');
        end = moment().endOf('month').format('YYYY/MM/DD');
        break;
      case 'lastMonth':
        start = moment().subtract(1, 'months').startOf('month').format('YYYY/MM/DD');
        end = moment().subtract(1, 'months').endOf('month').format('YYYY/MM/DD');
        break;
      case 'today':
        start = moment().format('YYYY/MM/DD');
        end = moment().format('YYYY/MM/DD');
        break;
      default:
        start = moment().format('YYYY/MM/DD');
        end = moment().format('YYYY/MM/DD');
    }
    setStartDate(start);
    setEndDate(end);
  };

  const handleDateChange = {
    start: (date) => {
      setStartDate(moment(date).format('YYYY/MM/DD'));
      refetch();
    },
    end: (date) => {
      setEndDate(moment(date).format('YYYY/MM/DD'));
      refetch();
    }
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    onEmailChange(newValue);
  };

  const handleToggleChange = (event) => {
    setIsCustom(event.target.checked);
    if (!event.target.checked) {
      setDates(selectedOption);
    }
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    onPeriodChange(event.target.value);
    setDates(event.target.value);
  };

  const Total = ({ totalSpentSeconds, workingTimeSeconds }) => (
    <Stack spacing={2} direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
      <Typography component="h5" variant="h5">
        <strong>Total</strong>
      </Typography>

      <Typography component="h5" variant="h5">
        <strong>
          {totalSpentSeconds === 0 ? '0' : formatDuration(totalSpentSeconds)} / {formatDuration(workingTimeSeconds)}
        </strong>
      </Typography>
    </Stack>
  );

  const { timeTracking } = data || {};

  return (
    <>
      {loading && <LoadingCardWrapper page={true} />}
      {error && 'Currently unavailable'}
      {!loading && !error && (
        <>
          <div style={{ width: '60%', float: 'right' }}>
            <Grid container spacing={2} sx={{ mb: 5 }}>
              <Grid item xs={12} container justifyContent="flex-end">
                <Select
                  value={tab}
                  onChange={(event) => handleTabChange(event, event.target.value)}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Select employee' }}
                  sx={{
                    marginRight: '16px',
                    '.MuiSelect-select': {
                      display: 'flex',
                      alignItems: 'center',
                      paddingTop: '8px',
                      paddingBottom: '8px'
                    }
                  }}
                >
                  {timeTracking.map(({ user: { email, firstname, lastname } }) => (
                    <MenuItem key={email} value={email}>
                      <AvatarGenerator
                        email={email}
                        firstname={firstname}
                        lastname={lastname}
                        style={{ width: '16px', height: '16px', marginRight: '8px' }}
                      />
                      <span>{firstname ? `${firstname} ${lastname}` : email}</span>
                    </MenuItem>
                  ))}
                </Select>

                <Stack spacing={2} direction="row">
                  {isCustom ? (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <Stack spacing={2} direction="row">
                        <DatePicker
                          label="Start Date"
                          value={moment(startDate, 'YYYY/MM/DD')}
                          onChange={(date) => handleDateChange.start(date)}
                          shouldDisableDate={(date) => moment(date).day() === 0 || moment(date).day() === 6}
                          renderInput={(params) => <TextField {...params} />}
                          sx={{
                            '.MuiInputBase-input': {
                              paddingTop: '8px',
                              paddingBottom: '8px'
                            }
                          }}
                        />
                        <DatePicker
                          label="End Date"
                          value={moment(endDate, 'YYYY/MM/DD')}
                          onChange={(date) => handleDateChange.end(date)}
                          shouldDisableDate={(date) => moment(date).day() === 0 || moment(date).day() === 6}
                          renderInput={(params) => <TextField {...params} />}
                          sx={{
                            '.MuiInputBase-input': {
                              paddingTop: '8px',
                              paddingBottom: '8px'
                            }
                          }}
                        />
                      </Stack>
                    </LocalizationProvider>
                  ) : (
                    <Select
                      value={selectedOption}
                      onChange={handleSelectChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      sx={{
                        '.MuiSelect-select': {
                          paddingTop: '8px',
                          paddingBottom: '8px'
                        }
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select Date Range
                      </MenuItem>
                      <MenuItem value="thisWeek">This Week</MenuItem>
                      <MenuItem value="lastWeek">Last Week</MenuItem>
                      <MenuItem value="thisMonth">This Month</MenuItem>
                      <MenuItem value="lastMonth">Last Month</MenuItem>
                      <MenuItem value="today">Today</MenuItem>
                    </Select>
                  )}
                  <FormControlLabel
                    control={<Switch checked={isCustom} onChange={handleToggleChange} />}
                    label="Fine&nbsp;Tune"
                  />
                </Stack>
              </Grid>
            </Grid>
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              {timeTracking.map(({ user: { email }, worklogs, totalSpentSeconds, workingTimeSeconds }) =>
                tab === email ? (
                  <Fragment key={email}>
                    <Total totalSpentSeconds={totalSpentSeconds} workingTimeSeconds={workingTimeSeconds} />
                    <TimeTrackingTable worklogs={worklogs} startDate={startDate} endDate={endDate} />
                  </Fragment>
                ) : null
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default TimeTrackingView;
