import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import MUIRichTextEditor from 'mui-rte';
import { uniqueId } from 'helpers/content';
import { stateToHTML } from 'draft-js-export-html';
import { useMutation } from '@apollo/client';
import { Button } from '@mui/material';
import { validationSchema } from './validation';
import { CommentFormWrapper } from './styles';
import { CREATE_TICKET_COMMENT_MUTATION } from './data';
import DropZone from 'components/Attachments/DropZone';

const AddCommentToTicket = ({ ticketId }) => {
  const [initialComment, setInitialComment] = useState({
    key: uniqueId(),
    value: ''
  });

  const [attachments, setAttachments] = useState([]);
  const removeAllAttachments = () => {
    setAttachments([]);
  };

  const { handleSubmit, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      comment: '',
      files: []
    }
  });

  const [addSupportTicketComment, { loading, error, data }] = useMutation(CREATE_TICKET_COMMENT_MUTATION, {
    refetchQueries: ['getSupportTicket'],
    onCompleted: () => {
      setInitialComment({
        key: uniqueId(),
        value: ''
      });
      removeAllAttachments();
    }
  });

  const onSubmit = async (formData) => {
    await addSupportTicketComment({
      variables: {
        ticketId,
        comment: formData.comment,
        files: formData.files
      }
    });
  };

  return (
    <CommentFormWrapper>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <MUIRichTextEditor
          label="Your message"
          key={initialComment.key}
          value={initialComment.value}
          onChange={(state) => {
            setValue('comment', stateToHTML(state.getCurrentContent()));
          }}
          toolbar={true}
          controls={[
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'undo',
            'redo',
            'link',
            'numberList',
            'bulletList',
            'quote'
          ]}
        />

        <DropZone
          setFieldValue={(_, value) => setValue('files', value)}
          attachments={attachments}
          setAttachments={setAttachments}
        />

        <div style={{ textAlign: 'right' }}>
          <Button type="submit" variant="contained" color="primary">
            Add Message
          </Button>
        </div>
      </form>

      {loading && <div>Loading...</div>}
      {error && <div>There's appears to be an error. Please try again later.</div>}
      {data && <div>Your comment has been submitted successfully.</div>}
    </CommentFormWrapper>
  );
};

export default AddCommentToTicket;
