import { useState, useEffect } from 'react';
import Moment from 'react-moment';
import { useDispatch } from 'react-redux';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { StyledChip, StyledLink, StyledPaper, StyledPaperInner } from './styles';
import { SUPPORT_TICKETS_QUERY } from './data';
import { setSupportCounts } from 'reducers/authReducer';
import { startCase, truncate } from 'helpers/content';
import Table from 'components/Table';
import LoadingCardWrapper from 'components/Wrappers/LoadingCardWrapper';

const SupportTicketsCard = ({ status, border, search, ticketsToShow, supportTicketCount }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);

  const { loading: queryLoading, error: queryError, data: queryData } = useQuery(SUPPORT_TICKETS_QUERY);

  useEffect(() => {
    setLoading(queryLoading);
    setError(queryError);
    setData(queryData);
  }, [queryLoading, queryError, queryData]);

  useEffect(() => {
    if (data) {
      const {
        support: { tickets }
      } = data || {};

      const openCount = tickets.filter((ticket) => ticket.fields.status.statusCategory.name !== 'Done').length;
      const closedCount = tickets.filter((ticket) => ticket.fields.status.statusCategory.name === 'Done').length;

      dispatch(
        setSupportCounts({
          openCount,
          closedCount
        })
      );
    }
  }, [data, dispatch]);

  if (loading) {
    return <LoadingCardWrapper />;
  }

  if (error) {
    return 'Currently unavailable';
  }

  const {
    support: { tickets }
  } = data || {};

  let ticketCount = 0;
  if (status === 'open') {
    ticketCount = tickets ? tickets.filter((ticket) => ticket.fields.status.statusCategory.name !== 'Done').length : 0;
  } else {
    ticketCount = tickets ? tickets.filter((ticket) => ticket.fields.status.statusCategory.name === 'Done').length : 0;
  }

  const statusMap = (ticket) => {
    if (status === 'open') {
      return ticket.fields.status.statusCategory.name !== 'Done';
    } else {
      return ticket.fields.status.statusCategory.name === 'Done';
    }
  };

  if (ticketCount === 0) {
    return (
      <StyledPaper>
        <StyledPaperInner>
          <Typography variant="body" component="p">
            You have no {status} tickets at the moment
          </Typography>
        </StyledPaperInner>
      </StyledPaper>
    );
  }

  return (
    <Table
      title={`${startCase(status)} Support Tickets`}
      columns={[
        { title: 'ID', field: 'id' },
        { title: 'Subject', field: 'subject' },
        { title: 'Description', field: 'description' },
        { title: 'Created', field: 'created_at' },
        { title: 'Status', field: 'status' },
        { field: 'view' }
      ]}
      data={tickets
        .filter((ticket) => statusMap(ticket))
        .map(({ id, key, fields }) => {
          return {
            id: key,
            subject: <StyledLink to={`/dashboard/support/ticket/${key}`}>{fields.summary}</StyledLink>,
            description: truncate(fields.description, { length: 60 }),
            created_at: <Moment format="Do MMMM YYYY">{fields.created}</Moment>,
            status: (
              <StyledChip
                label={startCase(status)}
                size="small"
                variant="outlined"
                open={fields.status.statusCategory.name === 'Done' ? true : false}
              />
            ),
            view: (
              <Button
                color="primary"
                variant="contained"
                size="small"
                component={Link}
                to={`/dashboard/support/ticket/${key}`}
              >
                View Ticket
              </Button>
            )
          };
        })}
      options={{
        pageSize: 5,
        sorting: true,
        draggable: false,
        search
      }}
      style={{
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0
      }}
      border={border}
      lastColumnWide={true}
    />
  );
};

export default SupportTicketsCard;
