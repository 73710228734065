import client from 'config/graphql';
import { authRef } from 'config/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { verificationStart, verificationSuccess, verificationError } from 'reducers/authReducer';
import { VERIFY_EMAIL_MUTATION } from './data';

export const verifyEmail = (token) => (dispatch) => {
  dispatch(verificationStart());

  return new Promise((resolve, reject) => {
    return onAuthStateChanged(authRef, async (user) => {
      if (user) {
        await authRef.currentUser.getIdToken(true).then(async (idToken) => {
          await client
            .mutate({
              mutation: VERIFY_EMAIL_MUTATION,
              variables: { token },
              context: {
                headers: {
                  authorization: idToken
                }
              }
            })
            .then(({ data }) => {
              const {
                verifyEmail: { emailVerified }
              } = data;

              dispatch(verificationSuccess());
              resolve(emailVerified);
            })
            .catch((error) => {
              dispatch(verificationError('Verification failed'));
              reject(error);
            });
        });
      } else {
        dispatch(verificationError('User not logged in'));
        reject(new Error('User not logged in'));
      }
    });
  });
};
