import { useState } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { doPasswordReset } from 'actions';
import { validationSchema } from './validation';
import { yupResolver } from '@hookform/resolvers/yup';
import * as ROUTES from 'constants/routes';

const PasswordForgetForm = () => {
  const dispatch = useDispatch();
  const lightMode = useSelector((state) => state.type.lightMode);
  const [submitBlocked, setSubmitBlocked] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: {
      email: ''
    },
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = ({ email }) => {
    if (!submitBlocked) {
      setSubmitBlocked(true);
      dispatch(doPasswordReset(email));
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="text"
            label="Email"
            autoComplete="email"
            margin="normal"
            required
            fullWidth
            disabled={false}
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : ''}
            style={{ marginBottom: '30px' }}
          />
        )}
      />

      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Button
            type="submit"
            size="large"
            variant="contained"
            color={lightMode ? 'primary' : 'secondary'}
            fullWidth
            disabled={isSubmitting}
          >
            Send reset email
          </Button>
        </Grid>

        <Grid item xs={6}>
          <Button
            component={Link}
            size="large"
            variant="outlined"
            color={lightMode ? 'primary' : 'secondary'}
            fullWidth
            to={ROUTES.AUTH_SIGN_IN}
          >
            Back to login
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default PasswordForgetForm;
