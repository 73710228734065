import { gql } from '@apollo/client';

export const CREATE_USER_QUERY = gql`
  mutation createUser($firstname: String!, $lastname: String!, $email: String!, $uid: ID!) {
    createUser(firstname: $firstname, lastname: $lastname, email: $email, uid: $uid) {
      firstname
      lastname
      email
      role
      emailVerified
      features
      projectCount
      agencies {
        id
        name
        projects {
          id
          title
          summary
          projectType
          websiteUrl
          screenshot {
            secure_url
          }
        }
      }
      projects {
        id
        title
        summary
        projectType
        websiteUrl
        screenshot {
          secure_url
        }
      }
      support {
        openCount
        closedCount
      }
    }
  }
`;
