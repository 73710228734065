import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { Button } from '@mui/material';
import { WarningSnackBar } from './styles';
import { VERIFICATION_EMAIL_MUTATION } from './data';

const VerifyEmail = ({ extraSidebar }) => {
  const { email, verified } = useSelector((state) => state.auth);

  const [requestVerificationEmail, { loading, data }] = useMutation(VERIFICATION_EMAIL_MUTATION);

  /** If the user is verified we do not need to show this message */
  if (verified) {
    return null;
  }

  let message = 'You have not verified your email address';
  let buttonText = 'Send verification email';

  if (loading) {
    message = 'Sending...';
  }

  if (data) {
    message = `An email has been sent to ${email}. If you have not received it, check your junk.`;
    buttonText = 'Re-send email';
  }

  return (
    <WarningSnackBar
      extraSidebar={extraSidebar}
      message={message}
      action={
        <Button
          color="secondary"
          size="small"
          variant="outlined"
          disableElevation={true}
          onClick={requestVerificationEmail}
        >
          {buttonText}
        </Button>
      }
    />
  );
};

/** Type checking */
VerifyEmail.propTypes = {
  extraSidebar: PropTypes.bool
};

export default VerifyEmail;
