import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { uniqueId } from 'helpers/content';
import { history } from 'helpers/History';
import { stateToHTML } from 'draft-js-export-html';
import { TextField, MenuItem, Typography } from '@mui/material';
import { SubmitButton } from './styles';
import { PROJECTS_QUERY, CREATE_SUPPORT_TICKET_MUTATION } from './data';
import { SUPPORT_TICKETS_QUERY } from 'components/Cards/SupportTicketsCard/data';
import { validationSchema } from './validation';
import MUIRichTextEditor from 'mui-rte';
import DropZone from 'components/Attachments/DropZone';
import FormHeader from 'components/FormHeader';
import * as ROUTES from 'constants/routes';

const SupportTicketForm = ({ single, location, align }) => {
  const { verified } = useSelector((state) => state.auth);
  const disabled = !verified;
  const priorities = ['Highest', 'High', 'Medium', 'Low', 'Lowest'];

  const [initialComment, setInitialComment] = useState({
    key: uniqueId(),
    value: ''
  });

  const [attachments, setAttachments] = useState([]);
  const removeAllAttachments = () => {
    setAttachments([]);
  };

  const { control, handleSubmit, setValue, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      subject: '',
      project: single || '',
      priority: 'Medium',
      files: [],
      comment: ''
    }
  });

  const { loading: projectsLoading, error: projectsError, data: projectsData } = useQuery(PROJECTS_QUERY);
  const [createSupportTicket, { loading: createLoading, error: createError, data: createData }] = useMutation(
    CREATE_SUPPORT_TICKET_MUTATION,
    {
      refetchQueries: [{ query: SUPPORT_TICKETS_QUERY }],
      onCompleted: (data) => {
        if (data.createSupportTicket) {
          history.push(`${ROUTES.DASHBOARD_SUPPORT}/ticket/${data.createSupportTicket.key}`);
        }
      }
    }
  );

  const onSubmit = async (values) => {
    try {
      await createSupportTicket({
        variables: {
          subject: values.project ? `${values.project}: ${values.subject}` : values.subject,
          priority: values.priority,
          comment: values.comment,
          files: values.files
        }
      });

      reset();
      setInitialComment({ key: uniqueId(), value: '' });
      removeAllAttachments();
    } catch (error) {
      console.error('Error creating support ticket: ', error);
    }
  };

  return (
    <>
      <FormHeader
        title="Create a support ticket"
        subtitle="Raise a support ticket and we will get back to you shortly"
        align={align}
        lightState={true}
      />

      {disabled && (
        <Typography
          component="p"
          style={{
            color: 'red',
            textAlign: 'center',
            marginTop: '20px',
            marginBottom: '20px'
          }}
        >
          You must verify your email address to be able to submit a support ticket
        </Typography>
      )}

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Controller
          name="subject"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              variant="outlined"
              label="Subject"
              margin="normal"
              required
              fullWidth
              disabled={disabled}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        <Controller
          name="priority"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              select
              variant="outlined"
              label="Urgency"
              margin="normal"
              required
              fullWidth
              disabled={disabled}
              error={!!error}
              helperText={error?.message}
            >
              {priorities.map((priority, index) => (
                <MenuItem key={index} value={priority}>
                  {priority}
                </MenuItem>
              ))}
            </TextField>
          )}
        />

        {!single && !projectsError && (
          <Controller
            name="project"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                select
                variant="outlined"
                label={projectsLoading ? 'Loading projects...' : 'Select Project'}
                margin="normal"
                fullWidth
                disabled={disabled || projectsLoading}
                error={!!error}
                helperText={error?.message}
              >
                <MenuItem value="">Select project</MenuItem>
                {projectsData?.projects?.map(({ title, projectCode }, index) => (
                  <MenuItem key={index} value={projectCode}>
                    {title}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        )}

        <MUIRichTextEditor
          id="support-form"
          label="Your message"
          key={initialComment.key}
          value={initialComment.value}
          onChange={(state) => setValue('comment', stateToHTML(state.getCurrentContent()))}
          toolbar={true}
          toolbarButtonSize="small"
          controls={[
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'undo',
            'redo',
            'link',
            'numberList',
            'bulletList',
            'quote'
          ]}
        />

        <DropZone
          setFieldValue={(_, value) => setValue('files', value)}
          attachments={attachments}
          setAttachments={setAttachments}
        />

        <SubmitButton
          type="submit"
          size="large"
          variant="contained"
          color="primary"
          disabled={disabled || createLoading}
        >
          Submit ticket
        </SubmitButton>
      </form>

      {createLoading && <div>Loading...</div>}
      {createError && <div>There's appears to be an error. Please try again later.</div>}
      {createData && <div>Your ticket has been submitted successfully.</div>}
    </>
  );
};

export default SupportTicketForm;
