import { gql } from '@apollo/client';

export const GET_USER_QUERY = gql`
  query user($uid: ID!) {
    user(uid: $uid) {
      uid
      firstname
      lastname
      email
      emailVerified
      metadata {
        lastSignInTime
        creationTime
      }
    }
  }
`;
