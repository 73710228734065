import client from 'config/graphql';
import { authRef } from 'config/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { GET_USER_QUERY } from './data';
import { setUser, signoutSuccess } from 'reducers/authReducer';

const fetchUser = () => (dispatch) => {
  return onAuthStateChanged(authRef, async (user) => {
    if (user) {
      await authRef.currentUser.getIdToken(true).then(async (idToken) => {
        /** Authenticate with the backend to get the user  */
        await client
          .query({
            query: GET_USER_QUERY,
            context: {
              headers: {
                authorization: idToken
              }
            }
          })
          .then(({ data: { user } }) => {
            if (user) {
              /** On successful login fetch the user role */
              dispatch(
                setUser({
                  firstname: user.firstname,
                  lastname: user.lastname,
                  email: user.email,
                  role: user.role,
                  verified: user.emailVerified,
                  projectCount: user.projectCount,
                  features: user.features,
                  loggedIn: true,
                  agencies: user.agencies,
                  projects: user.projects,
                  support: user.support
                })
              );
            } else {
              dispatch(signoutSuccess());
            }
          });
      });
    } else {
      dispatch(signoutSuccess());
    }
  });
};

export default fetchUser;
